import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Img from "gatsby-image"
import SEO from "../components/seo"
import IndexLayout from "../components/index-layout"

import "./index.css"

const IndexPage = () => {
    const data = useStaticQuery(graphql`query {
        logoImage: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
                fluid(maxWidth: 360) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }`);

    return (
        <IndexLayout innerStyle="index">
            <SEO title="Home" />
            <div className="content-wrapper">
                <main style={{
                    textAlign: "center"
                }}>
                    <Img
                        fluid={data.logoImage.childImageSharp.fluid}
                        style={{
                            maxWidth: "360px",
                            margin: "0 auto"
                        }}
                    />
                    <h1 style={{
                        marginTop: "1.45rem",
                        textAlign: "center"
                    }}>Yanase Takuma</h1>
                    <p><span style={{display: "inline-block"}}>システムエンジニア・柳瀬 琢磨の</span><span style={{display: "inline-block"}}>ポートフォリオ</span></p>
                    <ul className="index-nav">
                        <li><Link to="/about/">About</Link></li>
                        <li><Link to="/posts/">Posts</Link></li>
                    </ul>
                    <p>© {new Date().getFullYear()}, Yanase Takuma</p>
                </main>
            </div>
        </IndexLayout>
    );
}

export default IndexPage
